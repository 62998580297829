import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { IoIosArrowForward } from 'react-icons/io';
import classNames from 'classnames';

import './App.css';
import './style/index.scss';

function App() {
  return (
    <RecoilRoot>
      <div className={classNames('App')}>
        <section className='move-page'>
          <article>
            <h1>DSR 계산기</h1>
            <h2>DSR 계산법, 차주단위 DSR 40% 대출한도 계산하기</h2>

            <div className='btn-wrap'>
              <a href='https://awesome-ui.netlify.app/dsr' target='_self' rel='noreferrer'>
                <span>DSR 계산기</span>
                <IoIosArrowForward />
              </a>

              <a href='https://awesome-ui.netlify.app/calendar' target='_self' rel='noreferrer'>
                <span>청약 캘린더</span>
                <IoIosArrowForward />
              </a>

              <a href='https://awesome-ui.netlify.app/' target='_self' rel='noreferrer'>
                <span>실시간 검색어</span>
                <IoIosArrowForward />
              </a>
            </div>

            <div className='bottom-desc'>
              <h3>DSR 뜻</h3>
              <p>
                {' '}
                DSR이란 모든 대출의 연간 원리금을 연소득으로 나눈 비율로 <br /> 개인의 연봉에 따라 달라집니다.
              </p>

              <p>페이지 주소가 변경되었습니다.</p>
            </div>
          </article>
        </section>
      </div>
    </RecoilRoot>
  );
}

export default App;
